import * as React from 'react'

export const SunIcon = ({ ...props }) => (
  <svg
    width="468"
    height="468"
    viewBox="0 0 468 468"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M234 140.67C182.453 140.67 140.668 182.455 140.668 234.002C140.668 285.549 182.453 327.334 234 327.334C285.547 327.334 327.332 285.549 327.332 234.002C327.332 182.455 285.547 140.67 234 140.67ZM94 234.002C94 156.682 156.68 94.002 234 94.002C311.32 94.002 374 156.682 374 234.002C374 311.322 311.32 374.002 234 374.002C156.68 374.002 94 311.322 94 234.002Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M257.33 24V47.332C257.33 60.219 246.885 70.668 233.998 70.668C221.111 70.668 210.666 60.219 210.666 47.332V24C210.666 11.113 221.111 0.667999 233.998 0.667999C246.885 0.667999 257.33 11.113 257.33 24Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.01 69.01L118.506 85.51C127.619 94.6233 127.619 109.397 118.506 118.506C109.397 127.619 94.623 127.619 85.51 118.506L69.01 102.01C59.8967 92.8967 59.8967 78.123 69.01 69.01C78.1233 59.8967 92.897 59.8967 102.01 69.01Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M398.99 102.01L382.49 118.506C373.377 127.619 358.603 127.619 349.494 118.506C340.381 109.397 340.381 94.623 349.494 85.51L365.994 69.01C375.103 59.8967 389.877 59.8967 398.99 69.01C408.103 78.1233 408.103 92.897 398.99 102.01Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M257.33 420.67V444.002C257.33 456.889 246.885 467.334 233.998 467.334C221.111 467.334 210.666 456.889 210.666 444.002V420.67C210.666 407.783 221.111 397.334 233.998 397.334C246.885 397.334 257.33 407.783 257.33 420.67Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M382.49 349.49L398.99 365.994C408.103 375.103 408.103 389.877 398.99 398.99C389.877 408.103 375.103 408.103 365.994 398.99L349.494 382.49C340.381 373.377 340.381 358.603 349.494 349.49C358.603 340.381 373.377 340.381 382.49 349.49Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M118.5 382.49L102.004 398.99C92.8907 408.103 78.117 408.103 69.004 398.99C59.8907 389.877 59.8907 375.103 69.004 365.994L85.504 349.49C94.6173 340.381 109.391 340.381 118.5 349.49C127.613 358.603 127.613 373.377 118.5 382.49Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M444 257.33H420.668C407.781 257.33 397.332 246.885 397.332 233.998C397.332 221.111 407.781 210.666 420.668 210.666H444C456.887 210.666 467.332 221.111 467.332 233.998C467.332 246.885 456.887 257.33 444 257.33Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.33 257.33H23.998C11.111 257.33 0.666016 246.885 0.666016 233.998C0.666016 221.111 11.111 210.666 23.998 210.666H47.33C60.217 210.666 70.666 221.111 70.666 233.998C70.666 246.885 60.217 257.33 47.33 257.33Z"
      fill="currentColor"
    />
  </svg>
)
