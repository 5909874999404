import { useState, useEffect, useMemo } from 'react'

export const useLocalStorage = (key: string) => {
  const [state, setState] = useState<any>(null)

  useEffect(() => {
    setState(localStorage.getItem(key))
  }, [])

  const setWithLocalStorage = (nextState: string) => {
    setState(nextState)
    localStorage.setItem(key, nextState)
  }

  return [state, setWithLocalStorage]
}

export const useClickAway = (ref: any, handler: any) => {
  const refs = useMemo(() => (Array.isArray(ref) ? ref : [ref]), [ref])

  useEffect(() => {
    const listener = (event: any) => {
      if (
        !refs.length ||
        refs.find(({ current }) => current && current.contains(event.target))
      )
        return
      handler(event)
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler, refs])
}

export const useEscAway = (ref: any, handler: any) => {
  const refs = useMemo(() => (Array.isArray(ref) ? ref : [ref]), [ref])

  useEffect(() => {
    const listener = (event: any) => {
      if (!refs.length || event.which !== 27 || event.keyCode !== 27) return
      handler(event)
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [ref, handler, refs])
}
