import * as React from 'react'
import { Navigation } from './Navigation'
import { Helmet } from 'react-helmet'
import '../styles/base.css'
import '../styles/fonts/fonts.css'
import '../styles/tailwind.css'

export const Layout = ({
  children,
  title,
  description,
}: {
  children: React.ReactNode
  title: string
  description: string
}): JSX.Element => (
  <div className="text-gray-800 dark:text-gray-200 antialiased pt-14 lg:pt-20 flex flex-col h-full">
    <Helmet>
      <meta charSet="utf-8" />
      <title>Darren Galway :: {title}</title>
      <meta name="description" content={description} />
    </Helmet>
    <Navigation />
    {children}
  </div>
)

export default Layout
