import React, { ButtonHTMLAttributes, useState } from 'react'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { Link } from 'gatsby'
import { useLocalStorage } from '../hooks'
import { MoonIcon, SunIcon } from '../components/icons'

type ThemeType = 'light' | 'dark'

interface ToggleThemeButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  theme: ThemeType
  onClick: () => void
}

const linkStyles = `relative p-2 lg:p-4 h-full flex-1 justify-center lg:flex-none lg:first-of-type:mr-auto transition-all flex items-center hover:text-blue-600 text-sm lg:text-base`
const activeLinkStyles = `${linkStyles} text-blue-600 after:scale-1 after:opacity-1 after:transition-all after:content-[''] after:h-px after:bg-blue-600 after:absolute after:left-0 after:right-0 after:-bottom-px text-gray-900 dark:text-gray-100`

const ToggleThemeButton = ({
  onClick,
  theme,
  ...props
}: ToggleThemeButtonProps) => {
  return (
    <button
      {...{ onClick }}
      {...props}
      className="p-1 w-8 h-8 flex flex-none rounded-lg transition-ring focus:ring items-center justify-center text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-300"
    >
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={theme}
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false)
          }}
          classNames="fade"
        >
          {theme === 'light' ? (
            <SunIcon className="w-full h-full" />
          ) : (
            <MoonIcon className="w-full h-full" />
          )}
        </CSSTransition>
      </SwitchTransition>
    </button>
  )
}

const NavigationLink = ({
  to,
  children,
}: {
  to: string
  children: React.ReactNode
}) => (
  <Link
    getProps={({ isCurrent }) => ({
      className: isCurrent ? activeLinkStyles : linkStyles,
    })}
    to={to}
  >
    {children}
  </Link>
)

export const Navigation = () => {
  const [theme, setTheme] = useLocalStorage('theme')

  const toggleTheme = () => {
    if (theme === 'light') {
      document.documentElement.classList.add('dark')
      document.documentElement.classList.remove('light')
      setTheme('dark')
    } else {
      document.documentElement.classList.remove('dark')
      document.documentElement.classList.add('light')
      setTheme('light')
    }
  }

  return (
    <header className="fixed inset-0 bottom-auto z-20 h-14 lg:h-20 border-b border-gray-200 dark:border-gray-800 backdrop-blur-sm bg-gray-100/50 dark:bg-gray-900/50">
      <nav className="container flex mx-auto items-center pr-4 h-full text-gray-600 dark:text-gray-400 font-sans-medium">
        <NavigationLink to="/">
          Darren <span className="hidden lg:block ml-1"> Galway</span>
        </NavigationLink>
        <NavigationLink to="/resume/">Resume</NavigationLink>
        <NavigationLink to="/contact/">Contact</NavigationLink>
        <div
          className="w-[1px] mx-4 bg-gray-200 dark:bg-gray-800 h-full"
          role="presentation"
        ></div>
        {theme && (
          <ToggleThemeButton
            onClick={toggleTheme}
            theme={theme}
            aria-label="Change theme"
            title="Change theme"
          />
        )}
      </nav>
    </header>
  )
}
