import * as React from 'react'

export const MoonIcon = ({ ...props }) => (
  <svg
    width="457"
    height="465"
    viewBox="0 0 457 465"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M215.96 8.82401C221.304 15.0623 222.972 23.652 220.358 31.437C214.081 50.101 210.671 70.113 210.671 90.968C210.671 194.058 294.241 277.628 397.331 277.628C408.198 277.628 418.823 276.706 429.151 274.933C437.245 273.542 445.475 276.515 450.815 282.753C456.159 288.991 457.831 297.581 455.213 305.366C424.158 397.721 336.883 464.296 234.003 464.296C105.133 464.296 0.673309 359.836 0.673309 230.956C0.673309 115.616 84.3413 19.856 194.293 0.996007C202.387 -0.394593 210.617 2.57801 215.957 8.81631L215.96 8.82401ZM166.48 56.883C96.761 83.942 47.34 151.699 47.34 230.963C47.34 334.053 130.914 417.633 234.01 417.633C303.08 417.633 363.43 380.11 395.71 324.297C267.6 323.422 164.01 219.287 164.01 90.967C164.01 79.397 164.854 68.014 166.487 56.881L166.48 56.883Z"
      fill="currentColor"
    />
  </svg>
)
